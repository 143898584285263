module.exports = {
  'all': require('./collection/all'),
  'any': require('./collection/any'),
  'at': require('./collection/at'),
  'collect': require('./collection/collect'),
  'contains': require('./collection/contains'),
  'countBy': require('./collection/countBy'),
  'detect': require('./collection/detect'),
  'each': require('./collection/each'),
  'eachRight': require('./collection/eachRight'),
  'every': require('./collection/every'),
  'filter': require('./collection/filter'),
  'find': require('./collection/find'),
  'findLast': require('./collection/findLast'),
  'findWhere': require('./collection/findWhere'),
  'foldl': require('./collection/foldl'),
  'foldr': require('./collection/foldr'),
  'forEach': require('./collection/forEach'),
  'forEachRight': require('./collection/forEachRight'),
  'groupBy': require('./collection/groupBy'),
  'include': require('./collection/include'),
  'includes': require('./collection/includes'),
  'indexBy': require('./collection/indexBy'),
  'inject': require('./collection/inject'),
  'invoke': require('./collection/invoke'),
  'map': require('./collection/map'),
  'max': require('./math/max'),
  'min': require('./math/min'),
  'partition': require('./collection/partition'),
  'pluck': require('./collection/pluck'),
  'reduce': require('./collection/reduce'),
  'reduceRight': require('./collection/reduceRight'),
  'reject': require('./collection/reject'),
  'sample': require('./collection/sample'),
  'select': require('./collection/select'),
  'shuffle': require('./collection/shuffle'),
  'size': require('./collection/size'),
  'some': require('./collection/some'),
  'sortBy': require('./collection/sortBy'),
  'sortByAll': require('./collection/sortByAll'),
  'sortByOrder': require('./collection/sortByOrder'),
  'sum': require('./math/sum'),
  'where': require('./collection/where')
};
