var arrayMap = require('./arrayMap'),
    baseCallback = require('./baseCallback'),
    baseMap = require('./baseMap'),
    baseSortBy = require('./baseSortBy'),
    compareMultiple = require('./compareMultiple');

/**
 * The base implementation of `_.sortByOrder` without param guards.
 *
 * @private
 * @param {Array|Object|string} collection The collection to iterate over.
 * @param {Function[]|Object[]|string[]} iteratees The iteratees to sort by.
 * @param {boolean[]} orders The sort orders of `iteratees`.
 * @returns {Array} Returns the new sorted array.
 */
function baseSortByOrder(collection, iteratees, orders) {
  var index = -1;

  iteratees = arrayMap(iteratees, function(iteratee) { return baseCallback(iteratee); });

  var result = baseMap(collection, function(value) {
    var criteria = arrayMap(iteratees, function(iteratee) { return iteratee(value); });
    return { 'criteria': criteria, 'index': ++index, 'value': value };
  });

  return baseSortBy(result, function(object, other) {
    return compareMultiple(object, other, orders);
  });
}

module.exports = baseSortByOrder;
